import React from 'react';
import PropTypes from 'prop-types';
import { Button, Message } from 'react-bulma-components';

/**
 * Message Box
 */
const MessageBox = ({ title, msg, setShowMsgBox }) => (
  <Message color="standout">
    <Message.Header>
      {title}
      <Button remove onClick={() => setShowMsgBox(false)} />
    </Message.Header>
    <Message.Body backgroundColor="secondary">
      {msg}
    </Message.Body>
  </Message>
);

export default MessageBox;

MessageBox.propTypes = {
  /**
   * Message Box Title
   */
  title: PropTypes.string,
  /**
   * Message Box Message
   */
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Function to close message box
   */
  setShowMsgBox: PropTypes.func
};

MessageBox.defaultProps = {
  title: 'Msg Box Title',
  msg: 'Msg Box Message',
  setShowMsgBox: undefined
};
