import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Loader from 'Loader/Loader';
import { Block, Icon } from 'react-bulma-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRealmApp } from 'App/RealmAppProvider';
import { FaExclamationTriangle, FaCheck } from 'react-icons/fa';

/**
 * ConfirmEmailPage Component - Page to handle email confirmation process.
 */
const ConfirmEmailPage = ({ setMsgBox, setShowMsgBox }) => {
  const app = useRealmApp();
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const urlToken = query.get('token');
  const urlTokenId = query.get('tokenId');

  const completeRegistration = useCallback(async () => {
    let titleVar = '';
    let msgVar = '';
    let iconVar = '';
    try {
      await app.emailPasswordAuth.confirmUser({
        token: urlToken,
        tokenId: urlTokenId
      });
      const confirmedMsg = (
        <Block display="flex" alignItems="center">
          <Icon textSize={3} color="success" mr={3}>
            <FaCheck />
          </Icon>
          <p>
            Thank you. Your email has been confirmed.
            <br />
            You may now log in.
          </p>
        </Block>
      );
      titleVar = 'Email Confirmed';
      msgVar = confirmedMsg;
      iconVar = 'success';
    } catch (e) {
      const errorMsg = (
        <Block>
          <Block display="flex" alignItems="center">
            <Icon textSize={3} color="error" mr={3}>
              <FaExclamationTriangle />
            </Icon>
            <p>
              There has been an error.
              <br />
              Please contact Support for further assistance.
            </p>
          </Block>
          <Block className="help is-danger">{`Error: ${e.error}`}</Block>
        </Block>
      );
      titleVar = 'Email Confirmation Error';
      msgVar = errorMsg;
      iconVar = 'fail';
    }
    setMsgBox({
      title: titleVar,
      msg: msgVar,
      icon: iconVar
    });
    setShowMsgBox(true);
    navigate('/');
  }, [app.emailPasswordAuth, navigate, setMsgBox, setShowMsgBox, urlToken, urlTokenId]);

  useEffect(() => {
    setTimeout(() => {
      completeRegistration();
    }, 1000);
  }, [completeRegistration]);
  return (
    <Loader msg="Confirming email..." showLoader />
  );
};

export default ConfirmEmailPage;

ConfirmEmailPage.propTypes = {
  setShowMsgBox: PropTypes.func,
  setMsgBox: PropTypes.func
};

ConfirmEmailPage.defaultProps = {
  setShowMsgBox: undefined,
  setMsgBox: undefined
};
