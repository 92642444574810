import React, {
  useState, lazy, Suspense
} from 'react';
import PropTypes from 'prop-types';
import InputEmail from 'Login/InputEmail/InputEmail';
import InputPassword from 'Login/InputPassword/InputPassword';
import {
  Form, Button, Box, Heading
} from 'react-bulma-components';
import { Link, useNavigate } from 'react-router-dom';

import './RegisterBox.scss';
// import TermsConditions from 'Login/TermsConditions/TermsConditions';
import Loader from 'Loader/Loader';
import { useRealmApp } from 'App/RealmAppProvider';
import LoaderSmall from 'Loader/LoaderSmall';

const TermsConditions = lazy(() => import('Login/TermsConditions/TermsConditions').then());

/**
 * Register component for portal
 */
const RegisterBox = ({ backgroundColor }) => {
  const app = useRealmApp();

  const queryParams = new URLSearchParams(window.location.search);
  const urlEmail = queryParams.get('email');

  const prefilledEmail = urlEmail !== null ? urlEmail : '';
  const [formData, setFormData] = useState(
    {
      email: prefilledEmail,
      password: '',
      confirmPassword: ''
    }
  );
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);

  const checkPasswordsMatch = () => {
    if (formData.password !== formData.confirmPassword) {
      setErrorMsg('Passwords dont match');
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData, [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleRegistration = async (event) => {
    setShowLoader(true);
    const { email, password } = formData;
    try {
      await app.emailPasswordAuth.registerUser({
        email: email.toLowerCase(),
        password: password
      });
      setShowLoader(false);
      navigate('/thank-you');
    } catch (err) {
      setErrorMsg(err.error || 'Registration failed. Please try again.');
      setShowLoader(false);
    }
  };

  return (
    <>
      <Loader msg="Registering..." showLoader={showLoader} />
      <Box
        style={backgroundColor && {
          backgroundColor
        }}
      >
        <Suspense fallback={<LoaderSmall />}>
          <TermsConditions
            showTermsConditions={showTermsConditions}
            setShowTermsConditions={setShowTermsConditions}
          />
        </Suspense>
        <Heading>
          Register for Platform access
        </Heading>
        <Heading subtitle>
          Please complete all fields
        </Heading>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (checkPasswordsMatch()) {
            handleRegistration(event).then();
          }
        }}
        >
          <InputEmail value={formData.email} handleChange={handleChange} />
          <InputPassword name="password" placeHolder="Password" value={formData.password} handleChange={handleChange} />
          <InputPassword name="confirmPassword" placeHolder="Confirm Password" value={formData.confirmPassword} handleChange={handleChange} />
          <Form.Field>
            <Form.Control>
              <p style={{
                lineHeight: '22px',
                verticalAlign: 'middle'
              }}
              >
                By registering you agree to the&nbsp;
                <Button
                  type="button"
                  color="link"
                  inverted
                  className="RegisterBox-text-btn"
                  onClick={() => setShowTermsConditions(true)}
                >
                  Terms and Conditions.
                </Button>
              </p>
            </Form.Control>
            <Form.Help className="RegisterBox-error-div has-text-error">
              {errorMsg}
            </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Button.Group align="right">
                <Button
                  renderAs={Link}
                  to="/"
                  name="cancel-btn"
                  type="button"
                  color="standout"
                  inverted="true"
                  className="RegisterBox-inverted-btn"
                  onClick={() => setErrorMsg('')}
                >
                  Cancel
                </Button>
                <Button color="standout" submit>REGISTER</Button>
              </Button.Group>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </>
  );
};

export default RegisterBox;

RegisterBox.propTypes = {
  /**
   * What background color to use.
   */
  backgroundColor: PropTypes.string
};

RegisterBox.defaultProps = {
  backgroundColor: '#e9e9ea'
};
