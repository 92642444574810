import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Realm from 'realm-web';

const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  const app = React.useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      'You must call useRealmApp() inside of a <RealmAppProvider />'
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
  const [app] = React.useState(new Realm.App(appId));

  // Wrap the Realm.App object's user state with React state
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);

  const logIn = useCallback(async (credentials) => {
    const user = await app.logIn(credentials);
    // If successful, app.currentUser is the user that just logged in
    setCurrentUser(app.currentUser);
    return user;
  }, [app]);

  const logOut = useCallback(async () => {
    // Log out the currently active user
    await app.currentUser?.logOut();
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    setCurrentUser(app.currentUser);
  }, [app]);

  const removeUser = useCallback(async (user) => {
    await app.removeUser(user);
  }, [app]);

  const wrapped = useMemo(() => ({
    ...app,
    currentUser,
    logIn,
    logOut,
    removeUser
  }), [
    app,
    currentUser,
    logIn,
    logOut,
    removeUser
  ]);

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};

RealmAppProvider.propTypes = {
  appId: PropTypes.string,
  children: PropTypes.node
};

RealmAppProvider.defaultProps = {
  appId: '',
  children: undefined
};
