import React from 'react';
import { useRealmApp } from 'App/RealmAppProvider';
import {
  Button, Message, Icon, Content
} from 'react-bulma-components';
import LoginWrapper from 'Login/LoginWrapper/LoginWrapper';
import { FaExclamationTriangle } from 'react-icons/fa';

const NoCustomData = () => {
  const app = useRealmApp();

  return (
    <LoginWrapper>
      <Message>
        <Message.Header backgroundColor="standout">
          Error: No Custom Data
          <Button remove onClick={() => app.logOut()} />
        </Message.Header>
        <Message.Body style={{
          backgroundColor: '#e9e9ea'
        }}
        >
          <Content display="flex" className="mb-1">
            <Icon textSize={3} color="error" mr={3}>
              <FaExclamationTriangle />
            </Icon>
            <Content textSize={5}>uh-oh...</Content>
          </Content>
          <p>There seems to be a problem with this account.</p>
          <p>We can&apos;t find any user custom data - Please contact support.</p>
        </Message.Body>
      </Message>
    </LoginWrapper>

  );
};

export default NoCustomData;
