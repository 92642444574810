import React from 'react';
import { useRealmApp } from 'App/RealmAppProvider';
import NoCustomData from 'Errors/NoCustomData';
import PropTypes from 'prop-types';

const RequireCustomData = ({ children }) => {
  // Only render children user has custom data.
  const app = useRealmApp();
  return Object.prototype.hasOwnProperty.call(app.currentUser.customData, 'details') ? children : <NoCustomData />;
};

export default RequireCustomData;

RequireCustomData.propTypes = {
  children: PropTypes.node
};

RequireCustomData.defaultProps = {
  children: undefined
};
