import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Element, Modal
} from 'react-bulma-components';

import './Loader.scss';

/**
 * Loader - something to show while background process running.
 */
const Loader = ({ msg, showLoader }) => (
  <Modal show={showLoader} showClose={false}>
    <Modal.Content className="has-text-centered">
      <div className="Loader-ripple">
        <Element />
        <Element />
      </div>
      <Heading textColor="standout" textFamily="primary" className="is-uppercase" textSize={5}>{msg}</Heading>
    </Modal.Content>
  </Modal>
);

export default Loader;

Loader.propTypes = {
  /**
   * Message that will be displayed with loader
   */
  msg: PropTypes.string,
  /**
   * Boolean to show/hide loader
   */
  showLoader: PropTypes.bool
};

Loader.defaultProps = {
  msg: 'Loading...',
  showLoader: true
};
