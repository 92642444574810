import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Element } from 'react-bulma-components';

import './Loader.scss';

const style = {
  width: '100%'
};

/**
 * LoaderSmall - something to show while background process running.
 */
const LoaderSmall = ({ msg }) => (
  <div className="has-text-centered mt-6" style={style}>
    <div className="Loader-ripple">
      <Element />
      <Element />
    </div>
    <Heading textColor="standout" textFamily="primary" className="is-uppercase" textSize={5}>{msg}</Heading>
  </div>
);

export default LoaderSmall;

LoaderSmall.propTypes = {
  /**
   * Message that will be displayed with loader
   */
  msg: PropTypes.string
};

LoaderSmall.defaultProps = {
  msg: 'Loading...'
};
