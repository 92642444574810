import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'Loader/Loader';
import {
  Box, Heading, Form, Button, Block, Icon
} from 'react-bulma-components';
import InputPassword from 'Login/InputPassword/InputPassword';
import { useRealmApp } from 'App/RealmAppProvider';
import {
  useLocation, Link, useNavigate
} from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

/**
 * ResetPasswordBox Component - Page to enter new password to complete reset process.
 */
const ResetPasswordBox = ({ setMsgBox, setShowMsgBox }) => {
  const app = useRealmApp();
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const urlToken = query.get('token');
  const urlTokenId = query.get('tokenId');
  const [showLoader, setShowLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState(
    {
      password: '',
      confirmPassword: ''
    }
  );

  const resetComplete = (
    <Block display="flex" alignItems="center">
      <Icon textSize={3} color="success" mr={3}>
        <FaCheck />
      </Icon>
      <p>
        Password reset complete.
        <br />
        You may now log in.
      </p>
    </Block>
  );

  const checkPasswordsMatch = () => {
    if (formData.password !== formData.confirmPassword) {
      setErrorMsg('Passwords dont match');
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData, [name]: value
    });
  };

  const resetPassword = async (password, token, tokenId) => {
    setShowLoader(true);
    try {
      await app.emailPasswordAuth.resetPassword({
        token: token,
        tokenId: tokenId,
        password: password
      });
      setMsgBox({
        title: 'Password Reset',
        msg: resetComplete
      });
      setErrorMsg('');
      setShowMsgBox(true);
      setShowLoader(false);
      navigate('/');
    } catch (err) {
      setShowLoader(false);
      setErrorMsg(err.error);
    }
  };

  useEffect(() => {
    if (!urlToken || !urlTokenId) {
      setSubmitDisabled(true);
      setErrorMsg('No tokens present - you can not reset your password');
    }
  }, [urlToken, urlTokenId]);

  return (
    <>
      <Loader msg="Resetting Password..." showLoader={showLoader} />
      <Box backgroundColor="secondary">
        <Heading>
          Password Reset
        </Heading>
        <Heading subtitle>
          To complete your password reset enter a new password below.
        </Heading>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (checkPasswordsMatch()) {
            resetPassword(formData.password, urlToken, urlTokenId).then();
          }
        }}
        >
          <InputPassword name="password" placeHolder="Password" value={formData.password} handleChange={handleChange} />
          <InputPassword name="confirmPassword" placeHolder="Confirm Password" value={formData.confirmPassword} handleChange={handleChange} />
          <Form.Field>
            <Form.Help className="RegisterBox-error-div has-text-error">
              {errorMsg}
            </Form.Help>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Button.Group align="right">
                <Button
                  renderAs={Link}
                  to="/"
                  type="button"
                  color="standout"
                  inverted
                  className="LoginBox-inverted-btn"
                >
                  Cancel
                </Button>
                <Button color="standout" submit disabled={submitDisabled}>
                  SUBMIT
                </Button>
              </Button.Group>
            </Form.Control>
          </Form.Field>
        </form>
      </Box>
    </>
  );
};

export default ResetPasswordBox;

ResetPasswordBox.propTypes = {
  setShowMsgBox: PropTypes.func,
  setMsgBox: PropTypes.func
};

ResetPasswordBox.defaultProps = {
  setShowMsgBox: undefined,
  setMsgBox: undefined
};
