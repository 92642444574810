import React, { useState, useEffect } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider
} from '@apollo/client';
import PropTypes from 'prop-types';
import { useRealmApp } from './RealmAppProvider';

// Create an ApolloClient that connects to the provided Realm.App's GraphQL API
const createRealmApolloClient = (app) => {
  const link = new HttpLink({
    // Realm apps use a standard GraphQL endpoint, identified by their App ID
    uri: `https://ap-southeast-2.aws.services.cloud.mongodb.com/api/client/v2.0/app/${app.id}/graphql`,
    // A custom fetch handler adds the logged in user's access token to GraphQL requests
    fetch: async (uri, options) => {
      if (!app.currentUser) {
        throw new Error('Must be logged in to use the GraphQL API');
      }
      // Refreshing a user's custom data also refreshes their access token
      await app.currentUser.refreshCustomData();
      // Include the current user's access token in an Authorization header with
      // every request.
      // eslint-disable-next-line no-param-reassign
      options.headers.Authorization = `Bearer ${app.currentUser.accessToken}`;
      return fetch(uri, options);
    }
  });

  const cache = new InMemoryCache();

  return new ApolloClient({
    link,
    cache
  });
};

const RealmApolloProvider = ({ children }) => {
  //  Creates an ``ApolloClient`` object that connects to your app.
  const app = useRealmApp();
  const [client, setClient] = useState(createRealmApolloClient(app));
  useEffect(() => {
    setClient(createRealmApolloClient(app));
  }, [app]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default RealmApolloProvider;

RealmApolloProvider.propTypes = {
  children: PropTypes.node.isRequired
};
