import React from 'react';
import PropTypes from 'prop-types';
import {
  Hero, Container, Columns, Element
} from 'react-bulma-components';
import LogoContainer from 'Login/LogoContainer/LogoContainer';

const LoginWrapper = ({ children }) => (
  <Hero size="fullheight" className="LoginPage-bg full-height">
    <Hero.Body>
      <Container>
        <Columns vCentered>
          <Columns.Column>
            <LogoContainer />
          </Columns.Column>
          <Columns.Column>
            {children}
          </Columns.Column>
        </Columns>
      </Container>
    </Hero.Body>
    <Hero.Footer>
      <Container py={3} textAlign="center">
        <Element renderAs="a" href="https://governwith.us/" textColor="standout">
          GovernWith.us
        </Element>
      </Container>
    </Hero.Footer>
  </Hero>
);

export default LoginWrapper;

LoginWrapper.propTypes = {
  /**
   * ---
   */
  children: PropTypes.node
};

LoginWrapper.defaultProps = {
  children: undefined
};
