import React from 'react';
import {
  Block, Heading, Box
} from 'react-bulma-components';
import PropTypes from 'prop-types';

const ThankYouBox = ({ backgroundColor }) => (
  <Box
    style={backgroundColor && {
      backgroundColor
    }}
  >
    <Heading>
      Register for Portal access
    </Heading>
    <Block>
      Thank you
    </Block>
    <Block>
      Your registration for access to the GovernWith
      Portal is being processed.
    </Block>
    <Block>
      The next step in the process is to verify your email address. An email has been sent
      to the address you registered with, please follow the instructions in that email to
      complete this step.
    </Block>
    <Block>
      If you haven&apos;t received the email, please check your spam folder or alternatively
      contact us through the chat feature below.
    </Block>
  </Box>
);

export default ThankYouBox;

ThankYouBox.propTypes = {
  /**
   * What background color to use.
   */
  backgroundColor: PropTypes.string
};

ThankYouBox.defaultProps = {
  backgroundColor: '#e9e9ea'
};
