import React, { useState } from 'react';
import LoginBox from 'Login/LoginBox/LoginBox';
import ThankYouBox from 'Login/ThankYouBox/ThankYouBox';
import RegisterBox from 'Login/RegisterBox/RegisterBox';
import ForgotPasswordBox from 'Login/ForgotPasswordBox/ForgotPasswordBox';
import LoginWrapper from 'Login/LoginWrapper/LoginWrapper';
import ConfirmEmailPage from 'Login/ConfirmEmailPage/ConfirmEmailPage';
import ResetPasswordBox from 'Login/ResetPasswordBox/ResetPasswordBox';
import MessageBox from 'Login/MessageBox/MessageBox';
import { Block, Button } from 'react-bulma-components';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from 'react-router-dom';

import './LoginPage.scss';

/**
 * Login Page - holds all the login items. Where user can login or register for portal.
 */
const LoginPage = () => {
  const [showMsgBox, setShowMsgBox] = useState(false);
  const [msgBox, setMsgBox] = useState(
    {
      title: '',
      msg: ''
    }
  );

  return (
    <LoginWrapper>
      {showMsgBox
      && <MessageBox title={msgBox.title} msg={msgBox.msg} setShowMsgBox={setShowMsgBox} />}
      <Router>
        <Routes>
          <Route path="/confirm-email" element={<ConfirmEmailPage setMsgBox={setMsgBox} setShowMsgBox={setShowMsgBox} />} />
          <Route path="/reset-password" element={<ResetPasswordBox setMsgBox={setMsgBox} setShowMsgBox={setShowMsgBox} />} />
          <Route path="/thank-you" element={<ThankYouBox />} />
          <Route path="/forgot-password" element={<ForgotPasswordBox setMsgBox={setMsgBox} setShowMsgBox={setShowMsgBox} />} />
          <Route path="/register" element={<RegisterBox />} />
          <Route
            path="*"
            element={(
              <>
                <LoginBox title="Platform Log In" />
                {
                  !showMsgBox
                  && (
                    <Block>
                      <Button
                        renderAs={Link}
                        to="/register"
                        name="reg-btn"
                        color="secondary"
                        size="small"
                        type="button"
                        align="right"
                      >
                        Register for platform access
                      </Button>
                    </Block>
                  )
                }
              </>
            )}
          />
        </Routes>
      </Router>
    </LoginWrapper>
  );
};

export default LoginPage;
