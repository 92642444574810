import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'react-bulma-components';
import { FaEnvelope } from 'react-icons/fa';

/**
 * Email input field - to be used in forms
 */
const InputEmail = ({ value, name, handleChange, isDisabled }) => (
  <Form.Field>
    <Form.Control>
      <Form.Input
        placeholder="Email"
        type="email"
        value={value}
        name={name}
        required
        onChange={handleChange}
        disabled={isDisabled}
      />
      <Icon align="left" size="small">
        <FaEnvelope />
      </Icon>
    </Form.Control>
  </Form.Field>
);

export default InputEmail;

InputEmail.propTypes = {
  /**
   * Value from state to be displayed in input
   */
  value: PropTypes.string,
  /**
   * Unique name to identify input
   */
  name: PropTypes.string,
  /**
   * Function to update state when current input onChange event fired
   */
  handleChange: PropTypes.func,
  /**
   * Boolen is input field disabled
   */
  isDisabled: PropTypes.bool
};

InputEmail.defaultProps = {
  value: '',
  name: 'email',
  handleChange: undefined,
  isDisabled: false
};
